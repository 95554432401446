<template>
  <div ref="List" class="list">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="暂无数据了"
      @load="onLoad"
    >
      <van-cell v-for="item in list" :key="item.id" @click="clickNews(item)">
        <div class="li">
          <div class="left">
            <img src="https://thcdn.gggamedownload.com/source/mobileAssets/ziye/z133.png">
          </div>
          <div class="center">
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </div>
          <div class="right">
            <p class="month">{{ item.createTime | fileter_monteh }}</p>
            <p class="year">{{ item.createTime | fileter_year }}</p>
          </div>
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { reqGetNewsList } from '@/api/news'

export default {
  name: 'List',
  filters: {
    fileter_monteh(val) {
      if (val) {
        const str = val.substring(0, 10)
        const index = str.indexOf('-')
        const newVal = str.substring(index + 1)
        return newVal
      }
      return val
    },
    fileter_year(val) {
      if (val) {
        const str = val.substring(0, 10)
        const index = str.indexOf('-')
        const newVal = str.substring(0, index)
        return newVal
      }
      return val
    }
  },
  props: {
    tag: {
      type: null,
      required: true
    }
  },
  data() {
    return {
      active: '',
      titleList: [
        {
          id: 1,
          title: '最新',
          type: null
        },
        {
          id: 2,
          title: '新闻',
          type: 'news'

        },
        {
          id: 3,
          title: '公告',
          type: 'announcement'

        },
        {
          id: 4,
          title: '活动',
          type: 'gameWalkthrough'

        },
        {
          id: 5,
          title: '见闻纪',
          type: 'gameStrategy'
        }
      ],
      list: [],
      loading: false,
      finished: false,
      queryForm: {
        channel: 'th.gg.com',
        pageSize: 5,
        currentPage: 1,
        tag: this.$props.tag
      }
    }
  },
  methods: {
    async onLoad() {
      this.loading = true;
      const res = await reqGetNewsList(this.queryForm)
      this.list = [...this.list, ...res.data]
      this.queryForm.currentPage = res.currentPage + 1
      this.loading = false;
      if (this.list.length >= res.count) {
        this.finished = true;
      }
    },
    clickNews({ id }) {
      this.$router.push({
        path: '/newsDetails',
        query: {
          id
        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.list {
      padding: 0 21px;
       padding-right: 10px;
    height: 100%;
  // overflow-y: auto;

      .li {
        display: flex;
        text-align: center;
        .left {
          width: 91px;
          img {
            width: 100%;
          }
        }
        .center {
          width: 172px;
          padding-left: 13px;
            line-height: 100%;

          h3 {
            font-size: 13px;
            padding-top: 2px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
          p {
            padding-top: 7px;
            text-align-last: left;
            font-size: 12px;
            display: -webkit-box ;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            color: #A7A7A7;
          }
        }
        .right {
          padding-top: 5px;
          line-height: 100%;
          text-align: center;
          width: 40px;
          color: #555555;
          .month {
            min-width: 50px;
            font-size: 14px;
            font-weight: 700;
          }
          .year {
            min-width: 50px;
            font-size: 12px;
          }
        }
      }
}
</style>
